import { IconCategory2, IconChevronLeft } from '@tabler/icons'
import { get } from 'lodash'
import React, { useMemo } from 'react'

import { signIn, signOut } from '@stringke/next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import {
    AppShell,
    Box,
    Button,
    createStyles,
    Flex,
    Footer,
    Header,
    Space,
    Text,
    useMantineTheme,
} from '@mantine/core'
import { showNotification } from '@mantine/notifications'

import type { BaseProps } from '../pages/_app'
import { useIsAdmin, useIsLogin, useUser } from '../utils/hooks'
import { ColorSwitch } from './ColorSwitch'

function AppHeader() {
    const user = useUser()
    const router = useRouter()
    const isAdminPage = router.pathname.startsWith('/admin')

    const isLogin = useIsLogin()
    const departmentName = useMemo(() => get(user, 'department.name'), [user])
    const userName = useMemo(() => get(user, 'name', '未登陆'), [user])
    const userText = `${departmentName ? `${departmentName} ` : ''}${userName}`

    return (
        <Header
            height={62}
            zIndex={300}
        >
            <Flex
                gap='xs'
                justify='flex-start'
                align='center'
                direction='row'
                wrap='wrap'
                h={'100%'}
            >
                <Space w={'md'} />
                {isAdminPage && (
                    <IconChevronLeft
                        size={32}
                        onClick={() => {
                            router.back()
                        }}
                    ></IconChevronLeft>
                )}
                {!isAdminPage && <IconCategory2 size={32}></IconCategory2>}
                <Flex
                    onClick={() => {
                        if (isLogin) {
                            showNotification({
                                title: '正在退出',
                                message: '请稍后',
                            })
                            signOut()
                        } else {
                            showNotification({
                                title: '正在加载登录',
                                message: '请稍后',
                            })
                            signIn('wecom')
                        }
                    }}
                >
                    {userText || '未登陆，点我登陆'}
                </Flex>
                <Text ml={'auto'}>Go 订餐</Text>
                <Space w={'xs'} />
                <ColorSwitch />
                <Space w={'md'} />
            </Flex>
        </Header>
    )
}

const useFooterStyles = createStyles(() => ({
    root: {
        zIndex: 200,
    },
    group: {
        width: '100%',
        height: '100%',
    },
    item: {
        flex: 1,
        height: '100%',
        borderRadius: 0,
    },
}))

function AppFooter({ footerHeight }: { footerHeight: number }) {
    const { classes } = useFooterStyles()
    const router = useRouter()
    const { pathname } = router
    const isLogin = useIsLogin()
    const isAdmin = useIsAdmin()

    return (
        <Footer
            height={footerHeight}
            className={classes.root}
        >
            <Flex className={classes.group}>
                <Button
                    component={Link}
                    href='/'
                    variant={pathname === '/' ? 'filled' : 'subtle'}
                    color={pathname === '/' ? 'blue' : 'gray'}
                    className={classes.item}
                >
                    首页
                </Button>
                <Button
                    component={Link}
                    href='/menu'
                    variant={pathname === '/menu' ? 'filled' : 'subtle'}
                    color={pathname === '/menu' ? 'blue' : 'gray'}
                    className={classes.item}
                >
                    菜单
                </Button>
                <Button
                    component={Link}
                    href='/orders'
                    variant={pathname === '/orders' ? 'filled' : 'subtle'}
                    color={pathname === '/orders' ? 'blue' : 'gray'}
                    className={classes.item}
                >
                    订单
                </Button>
                {isAdmin && (
                    <Button
                        component={Link}
                        href='/admin'
                        variant={
                            pathname.startsWith('/admin') ? 'filled' : 'subtle'
                        }
                        color={pathname.startsWith('/admin') ? 'blue' : 'gray'}
                        className={classes.item}
                    >
                        管理
                    </Button>
                )}
                {!isLogin && (
                    <Button
                        component={Link}
                        href={'/api/auth/signin'}
                        color={'green'}
                        className={classes.item}
                    >
                        登陆
                    </Button>
                )}
            </Flex>
        </Footer>
    )
}

const useAuthButtonStyles = createStyles<string, { footerHeight: number }>(
    (theme, { footerHeight }) => ({
        root: {
            position: 'fixed',
            bottom: `${footerHeight}px`,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: theme.spacing.xl,
            pointerEvents: 'none',
            zIndex: 1000,
        },
        button: {
            pointerEvents: 'all',
        },
    }),
)

export function AuthButton({
    footerHeight,
}: { footerHeight: number } & BaseProps) {
    const { classes } = useAuthButtonStyles({ footerHeight: footerHeight + 12 })
    const isLogin = useIsLogin()
    const [isSigning, setIsSigning] = React.useState(false)

    if (isLogin) {
        return null
    }

    return (
        <Box className={classes.root}>
            <Button
                loading={isSigning}
                onClick={() => {
                    setIsSigning(true)
                    signIn('wecom')
                }}
                className={classes.button}
                variant='filled'
                size='lg'
                color='blue'
                fullWidth
            >
                登录
            </Button>
        </Box>
    )
}

export function CanteenLayout({
    children,
    ...props
}: React.PropsWithChildren<BaseProps>) {
    const footerHeight = 42
    const theme = useMantineTheme()
    return (
        <AppShell
            styles={{
                main: {
                    background:
                        theme.colorScheme === 'dark'
                            ? theme.colors.dark[8]
                            : theme.colors.gray[0],
                },
                body: {
                    zIndex: 50,
                },
            }}
            footer={<AppFooter footerHeight={footerHeight} />}
            header={<AppHeader />}
        >
            {children}
            <AuthButton
                footerHeight={footerHeight}
                {...props}
            />
        </AppShell>
    )
}
