/* eslint-disable @typescript-eslint/no-explicit-any */
import type { User } from '@prisma/client'
import {
    addMonths,
    addYears,
    eachDayOfInterval,
    eachMonthOfInterval,
    eachWeekOfInterval,
    endOfMonth,
    endOfWeek,
    format,
    isAfter,
    isBefore,
    isEqual,
    set,
    setDefaultOptions,
    setMonth,
    setYear,
    startOfMonth,
    startOfToday,
    startOfWeek,
    subMonths,
    subYears,
} from 'date-fns'
import { zhCN } from 'date-fns/locale'
import { get } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import UserParser, { type IResult } from 'ua-parser-js'

import { useSession } from '@stringke/next-auth/react'

import { showNotification } from '@mantine/notifications'

import { api } from './api'

setDefaultOptions({ locale: zhCN })

export function useUserAgent() {
    const [userAgent, setUserAgent] = React.useState<string | null>(null)

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            if (userAgent === null) {
                setUserAgent(window.navigator.userAgent)
            }
        }
    }, [userAgent])

    const [userAgentParsed, setUserAgentParsed] =
        React.useState<IResult | null>(null)

    React.useEffect(() => {
        if (userAgent !== null) {
            setUserAgentParsed(new UserParser().setUA(userAgent).getResult())
        }
    }, [userAgent])

    return userAgentParsed
}

export function useIsMobile() {
    const userAgentParsed = useUserAgent()

    return userAgentParsed?.device?.type === 'mobile'
}

export function useIsWecom() {
    const userAgentParsed = useUserAgent()

    return userAgentParsed?.browser?.name === 'WeChat'
}

export enum Month {
    JANUARY,
    FEBRUARY,
    MARCH,
    APRIL,
    MAY,
    JUNE,
    JULY,
    AUGUST,
    SEPTEMBER,
    OCTOBER,
    NOVEMBER,
    DECEMBER,
}

export enum Day {
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
}

export interface Options {
    /**
     * What day a week starts on within the calendar matrix.
     *
     * @default Day.SUNDAY
     */
    weekStartsOn?: Day

    /**
     * The initial viewing date.
     *
     * @default new Date()
     */
    viewing?: Date

    /**
     * The initial date(s) selection.
     *
     * @default []
     */
    selected?: Date[]

    /**
     * The number of months in the calendar.
     *
     * @default 1
     */
    numberOfMonths?: number
}

const inRange = (date: Date, min: Date, max: Date) =>
    (isEqual(date, min) || isAfter(date, min)) &&
    (isEqual(date, max) || isBefore(date, max))

const clearTime = (date: Date) =>
    set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })

export const useCalender = ({
    weekStartsOn = Day.SUNDAY,
    viewing: initialViewing = new Date(),
    selected: initialSelected = [],
    numberOfMonths = 1,
}: Options = {}) => {
    const [viewRange, setViewRange] = useState<'week' | 'month'>('month')
    const [viewing, setViewing] = useState<Date>(initialViewing)

    const viewToday = useCallback(
        () => setViewing(startOfToday()),
        [setViewing],
    )

    const viewMonth = useCallback(
        (month: Month) => setViewing((v) => setMonth(v, month)),
        [],
    )

    const viewPreviousMonth = useCallback(
        () => setViewing((v) => subMonths(v, 1)),
        [],
    )

    const viewNextMonth = useCallback(
        () => setViewing((v) => addMonths(v, 1)),
        [],
    )

    const viewYear = useCallback(
        (year: number) => setViewing((v) => setYear(v, year)),
        [],
    )

    const viewPreviousYear = useCallback(
        () => setViewing((v) => subYears(v, 1)),
        [],
    )

    const viewNextYear = useCallback(
        () => setViewing((v) => addYears(v, 1)),
        [],
    )

    const viewSelectedWeek = useCallback(() => {
        setViewing(startOfToday)
        setViewRange('week')
    }, [])

    const viewRangeWeek = useCallback((week: Date) => {
        setViewing(week)
        setViewRange('week')
    }, [])

    const viewRangeMonth = useCallback((week: Date) => {
        setViewing(week)
        setViewRange('month')
    }, [])

    const viewSelectedMonth = useCallback((day: Date) => {
        setViewRange('month')
        setViewing(day)
    }, [])

    const [selected, setSelected] = useState<Date[]>(
        initialSelected.map(clearTime),
    )

    const clearSelected = () => setSelected([])

    const isSelected = useCallback(
        (date: Date) => selected.findIndex((s) => isEqual(s, date)) > -1,
        [selected],
    )

    const select = useCallback(
        (date: Date | Date[], replaceExisting?: boolean) => {
            if (replaceExisting) {
                setSelected(Array.isArray(date) ? date : [date])
            } else {
                setSelected((selectedItems) =>
                    selectedItems.concat(Array.isArray(date) ? date : [date]),
                )
            }
        },
        [],
    )

    const deselect = useCallback(
        (date: Date | Date[]) =>
            setSelected((selectedItems) =>
                Array.isArray(date)
                    ? selectedItems.filter(
                          (s) =>
                              !date
                                  .map((d) => d.getTime())
                                  .includes(s.getTime()),
                      )
                    : selectedItems.filter((s) => !isEqual(s, date)),
            ),
        [],
    )

    const toggle = useCallback(
        (date: Date, replaceExisting?: boolean) => {
            if (isSelected(date)) {
                deselect(date)
            } else {
                select(date, replaceExisting)
            }
        },
        [deselect, isSelected, select],
    )

    const selectRange = useCallback(
        (start: Date, end: Date, replaceExisting?: boolean) => {
            if (replaceExisting) {
                setSelected(eachDayOfInterval({ start, end }))
            } else {
                setSelected((selectedItems) =>
                    selectedItems.concat(eachDayOfInterval({ start, end })),
                )
            }
        },
        [],
    )

    const deselectRange = useCallback((start: Date, end: Date) => {
        setSelected((selectedItems) =>
            selectedItems.filter(
                (s) =>
                    !eachDayOfInterval({
                        start,
                        end,
                    })
                        .map((d) => d.getTime())
                        .includes(s.getTime()),
            ),
        )
    }, [])

    const calendar = useMemo<Date[][][]>(() => {
        if (viewRange === 'week') {
            return [
                [viewing].map((week) =>
                    eachDayOfInterval({
                        start: startOfWeek(week, { weekStartsOn }),
                        end: endOfWeek(week, { weekStartsOn }),
                    }),
                ),
            ]
        }

        return eachMonthOfInterval({
            start: startOfMonth(viewing),
            end: endOfMonth(addMonths(viewing, numberOfMonths - 1)),
        }).map((month) =>
            eachWeekOfInterval(
                {
                    start: startOfMonth(month),
                    end: endOfMonth(month),
                },
                { weekStartsOn },
            ).map((week) =>
                eachDayOfInterval({
                    start: startOfWeek(week, { weekStartsOn }),
                    end: endOfWeek(week, { weekStartsOn }),
                }),
            ),
        )
    }, [viewRange, viewing, weekStartsOn, numberOfMonths])

    const weekTexts = useMemo(() => {
        const week = eachDayOfInterval({
            start: startOfWeek(viewing, { weekStartsOn }),
            end: endOfWeek(viewing, { weekStartsOn }),
        })
        return week.map((d) => format(d, 'E'))
    }, [viewing, weekStartsOn])

    return {
        clearTime,
        inRange,
        viewing,
        setViewing,
        viewToday,
        viewMonth,
        viewPreviousMonth,
        viewNextMonth,
        viewYear,
        viewPreviousYear,
        viewNextYear,
        viewSelectedWeek,
        viewRangeWeek,
        selected,
        setSelected,
        clearSelected,
        isSelected,
        select,
        deselect,
        toggle,
        selectRange,
        deselectRange,
        calendar,
        setViewRange,
        viewRange,
        weekTexts,
        viewSelectedMonth,
        viewRangeMonth,
    }
}

export function useIsLogin() {
    const user = useUser()

    return !!user
}

export function useUser() {
    const { data: session } = useSession({ required: false })
    const [user, setUser] = useState<null | User>(null)
    React.useEffect(() => {
        const userId = get(session, 'user.id', null)

        if (session && userId) {
            setUser(session.user as never)
        }
    }, [session])

    return user || null
}

export function useIsAdmin() {
    const user = useUser()
    return user?.isAdmin || false
}

export function useInitWechatSdk() {
    const { mutateAsync } = api.adminMenu.wecomTicket.useMutation()
    return React.useCallback(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return new Promise<any>(async (resolve, reject) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const wx = (window as any).wx

            if (!wx) {
                return reject()
            }
            if (wx.configed) {
                return resolve(wx)
            }

            let url = window.location.href
            if (url.indexOf('#') > -1) {
                url = url.split('#')[0] as string
            }

            const data = await mutateAsync({
                url: url,
            })

            wx.ready(() => {
                resolve(wx)
            })

            wx.error((res: any) => {
                showNotification({
                    title: '错误',
                    message: '微信初始化失败',
                })
                reject(res)
            })

            const apiList = ['chooseMessageFile', 'getLocalFileData']

            wx.config({
                debug: false,
                appId: data.corpid,
                timestamp: data.timestamp,
                nonceStr: data.noncestr,
                signature: data.signature,
                jsApiList: apiList,
            })
            wx.agentConfig({
                corpid: data.corpid,
                agentid: data.agentid,
                timestamp: data.timestamp,
                nonceStr: data.noncestr,
                signature: data.signature,
                jsApiList: apiList,
            })
        })
    }, [mutateAsync])
}

export function useHtmlUpload() {
    // base64
    return React.useCallback(() => {
        return new Promise<string>((resolve, reject) => {
            const input = document.createElement('input')
            input.type = 'file'
            // *.xls, *.xlsx
            input.accept =
                'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            input.onchange = (e) => {
                const file = (e.target as HTMLInputElement).files?.[0]
                if (!file) {
                    return reject()
                }
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => {
                    resolve(reader.result as string)
                }
            }
            setTimeout(() => {
                input.click()
            }, 10)
        })
    }, [])
}

export function useUpload() {
    const isWecom = useIsWecom()
    const getSdk = useInitWechatSdk()
    const webUpload = useHtmlUpload()

    return React.useCallback(() => {
        return new Promise<string>(async (resolve, reject) => {
            if (isWecom) {
                const sdk = await getSdk()
                // select xls xlsx
                sdk.invoke(
                    'chooseMessageFile',
                    {
                        count: 1,
                        type: 'file',
                    },
                    function (res: any) {
                        // 这里是回调函数
                        if (res.err_msg == 'chooseMessageFile:ok') {
                            const file = res.tempFiles[0]
                            sdk.invoke(
                                'getLocalFileData',
                                {
                                    localId: file.localId,
                                },
                                function (res: any) {
                                    if (res.err_msg == 'getLocalFileData:ok') {
                                        resolve(res.localData)
                                    } else {
                                        reject()
                                    }
                                },
                            )
                        } else {
                            reject()
                        }
                    },
                )
            } else {
                const base64 = await webUpload()
                resolve(base64)
            }
        })
    }, [getSdk, isWecom, webUpload])
}
